import React, { useContext, useEffect, useRef } from "react";
import {
  BecomeResponderFormContainer,
  StyledPhoneInput,
  GenderListContainer,
  TransportationDropDownContainer,
  StyledCountryDropDown,
  StyledStateDropDown,
  StyledStateDropDownContainer,
} from "./style";
import MyInput from "../Elements/MyInput";
import { MyAppContext } from "../../context/MyAppContext";
import { useIntl } from "react-intl";
import "react-phone-number-input/style.css";
import MyDatePicker from "../Elements/MyDatePicker";
import { MisterImage, MadamImage } from "../../assests";
import MyButton from "../Elements/MyButton/index";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import { BsPatchCheckFill } from "react-icons/bs";
import { FaTimes } from "react-icons/fa";
import useOutsideClick from "../../hooks/useOutsideClick";
import axios from "axios";
import moment from "moment";
import { useMediaQuery } from "react-responsive";

const BecomeResponderForm = () => {
  const transportationDropdown = useRef();
  const {
    firstName,
    setFirstName,
    lastName,
    setLastName,
    email,
    setEmail,
    phone,
    setPhone,
    zipCode,
    setZipCode,
    dateOfBirth,
    setDateOfBirth,
    gender,
    setGender,
    setNotification,
    selectedTransactionType,
    setSelectedTransactionType,
    isTransportationTypeDropDownOpen,
    setIsTransportationTypeDropDownOpen,
    address,
    setAddress,
    city,
    setCity,
    country,
    setCountry,
    state,
    setState,
    setCountryCode,
  } = useContext(MyAppContext);
  const intl = useIntl();

  const isMobile = useMediaQuery({ query: `(max-width: 768px)` });
  const isTablet = useMediaQuery({ query: `(max-width: 1000px)` });

  useEffect(() => {
    setFirstName("");
    setLastName("");
    setEmail("");
    setPhone("");
    setZipCode("");
    setDateOfBirth("");
    setGender("");
    setSelectedTransactionType("");
    setIsTransportationTypeDropDownOpen(false);
    setAddress("");
    setCity("");
    setCountry("France");
    setState("");
    setCountryCode("");
  }, []);

  const genderList = [
    {
      name: intl.formatMessage({
        id: "MODAL.INPUT.GENDER.MALE",
      }),
      id: 1,
    },
    {
      name: intl.formatMessage({
        id: "MODAL.INPUT.GENDER.FEMALE",
      }),
      id: 2,
    },
  ];

  const disableButton = () => {
    let emailCheck =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    let isValid = emailCheck.test(email);
    if (
      firstName === "" ||
      lastName === "" ||
      phone === "" ||
      zipCode === "" ||
      gender === "" ||
      selectedTransactionType === "" ||
      address === "" ||
      city === "" ||
      country === ""
    ) {
      return true;
    } else {
      if (isValid) {
        return false;
      } else {
        return true;
      }
    }
  };

  const transportaionTypesList = [
    intl.formatMessage({
      id: "TRANSPORT_TYPE_FOOT",
    }),
    intl.formatMessage({
      id: "TRANSPORT_TYPE_MOTO",
    }),
    intl.formatMessage({
      id: "TRANSPORT_TYPE_BIKE",
    }),
    intl.formatMessage({
      id: "TRANSPORT_TYPE_CAR",
    }),
  ];


  const becomeResponderHandler = () => {
      const data = {
        firstName,
        lastName,
        email,
        dateOfBirth: moment(dateOfBirth).format("yyyy-MM-DD"),
        phoneNumber: phone,
        gender: gender.name,
        address: address,
        city: city,
        state: state,
        zipCode: zipCode,
        country: country,
        transportationType: selectedTransactionType?.toLowerCase(),
      }
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/form`,
        data,
      )
      .then((res) => {
          setNotification({
            message: intl.formatMessage({
              id: "MODAL.BECOME_RESPONDER.NOTIFICATION",
            }),
            table: ``,
            backGroundColor: `#34CD77`,
            icon: <BsPatchCheckFill color="white" size={20} />,
          });
          setFirstName("");
          setLastName("");
          setEmail("");
          setPhone("");
          setZipCode("");
          setDateOfBirth("");
          setGender("");
          setSelectedTransactionType("");
          setIsTransportationTypeDropDownOpen(false);
          setAddress("");
          setCity("");
          setCountry("France");
          setState("");
          setCountryCode("");
      }).catch((err) => {
        const errors = err?.response?.data.errors;
        if(errors && errors?.length > 0){
          return setNotification({
            message: errors[0].msg,
            backGroundColor: `#ED2626`,
            icon: <FaTimes color="white" size={20} />,
          });
        } else {
          return setNotification({
            message: intl.formatMessage({
              id: "MODAL.BECOME_RESPONDER.NOTIFICATION_ERROR",
            }),
            backGroundColor: `#ED2626`,
            icon: <FaTimes color="white" size={20} />,
          });
        }
        })
  };

  useOutsideClick(transportationDropdown, () => {
    setIsTransportationTypeDropDownOpen(false);
  });

  return (
      <BecomeResponderFormContainer
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: "19px",
          }}
        > 
        <h2 className="header">{intl.formatMessage({
        id: "MODAL.BECOME_RESPONDER",
      })}</h2>
      <h4 className="sub-header">{intl.formatMessage({
        id: "MODAL.BECOME_RESPONDER_SUB",
      })}</h4>
        </div>
          <>
            <div className="d-flex">
              <MyInput
                label={intl.formatMessage({
                  id: "MODAL.INPUT.FIRST_NAME",
                })}
                value={firstName}
                className="input-item"
                onChange={(e) => {
                  let value = e.target.value;
                  setFirstName(value);
                }}
                placeholder={intl.formatMessage({
                  id: "MODAL.INPUT.FIRST_NAME.PLACEHOLDER",
                })}
              />
              <MyInput
                label={intl.formatMessage({
                  id: "MODAL.INPUT.LAST_NAME",
                })}
                value={lastName}
                onChange={(e) => {
                  let value = e.target.value;
                  setLastName(value);
                }}
                className="input-item"
                placeholder={intl.formatMessage({
                  id: "MODAL.INPUT.LAST_NAME.PLACEHOLDER",
                })}
              />
            </div>
            <div className="d-flex">
              <MyInput
                label={intl.formatMessage({
                  id: "MODAL.INPUT.EMAIL",
                })}
                type="email"
                value={email}
                className="input-item"
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                placeholder={intl.formatMessage({
                  id: "MODAL.INPUT.EMAIL.PLACEHOLDER",
                })}
              />
              <div className="phone-number-div">
                <label
                  style={{
                    color: phone !== "" ? "#5573A0" : "#5573A0",
                  }}
                >
                  {intl.formatMessage({
                    id: "MODAL.INPUT.PHONE_NUMBER",
                  })}
                </label>
                <StyledPhoneInput
                  placeholder={intl.formatMessage({
                    id: "MODAL.INPUT.PHONE_NUMBER.PLACEHOLDER",
                  })}
                  international
                  value={phone}
                  defaultCountry="FR"
                  limitMaxLength={true}
                  onChange={setPhone}
                  countryCallingCodeEditable={false}
                />
              </div>
            </div>
            <div className="d-flex">
              <MyDatePicker
                label={intl.formatMessage({
                  id: "MODAL.INPUT.DATE_OF_BIRTH",
                })}
                value={dateOfBirth}
                className="input-item"
                onChange={(date) => {
                  setDateOfBirth(date);
                }}
                width={
                  isMobile || isTablet
                    ? "100%"
                    : "100%"
                }
                placeholder={intl.formatMessage({
                  id: "MODAL.INPUT.DATE_OF_BIRTH.PLACEHOLDER",
                })}
              />
              <div className="d-flex-column">
                <label
                  style={{
                    color: gender !== "" ? "#5573A0" : "#5573A0",
                  }}
                >
                  {intl.formatMessage({
                    id: "MODAL.INPUT.GENDER",
                  })}
                </label>
                <GenderListContainer>
                  {genderList.map((genderEl, index) => {
                    return (
                      <div
                        className="gender-div"
                        style={{
                          backgroundColor:
                            genderEl?.id === gender?.id
                              ? "rgb(200 220 250 / 51%)"
                              : "rgb(200 220 250 / 18%)",
                          color:
                            genderEl?.id === gender?.id ? "#056dff" : "#5573A0",
                        }}
                        onClick={() => {
                          setGender(genderEl);
                        }}
                        key={index}
                      >
                        <img
                          src={genderEl?.id === 1 ? MisterImage : MadamImage}
                          style={{
                            filter:
                              genderEl?.id === 2
                                ? "invert(54%) sepia(41%) saturate(4923%) hue-rotate(179deg) brightness(88%) contrast(101%)"
                                : "",
                            opacity: genderEl?.id === gender?.id ? "1" : "0.2",
                            visibility:
                              isTablet && isTransportationTypeDropDownOpen
                                ? "hidden"
                                : "visible",
                          }}
                          alt=""
                        />
                        {genderEl?.id === gender?.id ? (
                          <b>{genderEl.name}</b>
                        ) : (
                          <span>{genderEl.name}</span>
                        )}
                      </div>
                    );
                  })}
                </GenderListContainer>
              </div>
            </div>
            <div className="d-flex">
              <TransportationDropDownContainer ref={transportationDropdown}>
                <label
                  style={{
                    color:
                      selectedTransactionType !== "" ? "#5573A0" : "#5573A0",
                  }}
                >
                  {intl.formatMessage({
                    id: "MODAL.INPUT.TRANSPORTATION_DROPDOWN",
                  })}
                </label>
                <div className="select-transportation">
                  {selectedTransactionType !== "" ? (
                    <span
                      className="select"
                      style={{
                        cursor: "pointer",
                        width: "100%",
                      }}
                      onClick={() => {
                        setIsTransportationTypeDropDownOpen(
                          !isTransportationTypeDropDownOpen
                        );
                      }}
                    >
                      {selectedTransactionType}
                    </span>
                  ) : (
                    <span
                      className="un-select"
                      style={{
                        cursor: "pointer",
                        width: "100%",
                      }}
                      onClick={() => {
                        setIsTransportationTypeDropDownOpen(
                          !isTransportationTypeDropDownOpen
                        );
                      }}
                    >
                      {intl.formatMessage({
                        id: "MODAL.INPUT.TRANSPORTATION_DROPDOWN.SELECT_TYPE",
                      })}
                    </span>
                  )}
                  {isTransportationTypeDropDownOpen ? (
                    <FiChevronUp
                      style={{
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setIsTransportationTypeDropDownOpen(
                          !isTransportationTypeDropDownOpen
                        );
                      }}
                    />
                  ) : (
                    <FiChevronDown
                      style={{
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setIsTransportationTypeDropDownOpen(
                          !isTransportationTypeDropDownOpen
                        );
                      }}
                    />
                  )}
                  {isTransportationTypeDropDownOpen ? (
                    <div className="dropdown-list">
                      {transportaionTypesList.map((el, i) => {
                        return (
                          <span
                            onClick={() => {
                              setSelectedTransactionType(el);
                              setIsTransportationTypeDropDownOpen(
                                !isTransportationTypeDropDownOpen
                              );
                            }}
                            style={{
                              width: "100%",
                            }}
                            key={i}
                          >
                            {el}
                          </span>
                        );
                      })}
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </TransportationDropDownContainer>
              <MyInput
                label={intl.formatMessage({
                  id: "MODAL.INPUT.ADDRESS",
                })}
                value={address}
                className="input-item"
                onChange={(e) => {
                  setAddress(e.target.value);
                }}
                placeholder={intl.formatMessage({
                  id: "MODAL.INPUT.ADDRESS.PLACEHOLDER",
                })}
              />
            </div>
            <div className="d-flex">
              <MyInput
                label={intl.formatMessage({
                  id: "MODAL.INPUT.CITY",
                })}
                value={city}
                onChange={(e) => {
                  setCity(e.target.value);
                }}
                className="input-item"
                placeholder={intl.formatMessage({
                  id: "MODAL.INPUT.CITY.PLACEHOLDER",
                })}
              />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "2px",
                  width: isMobile || isTablet ? "100%" : "",
                }}
              >
                <label
                  style={{
                    color: country !== "" ? "#5573A0" : "#5573A0",
                    fontFamily: "human_sans_400",
                    fontSize: "11px",
                    lineHeight: "12px",
                  }}
                >
                  {intl.formatMessage({
                    id: "MODAL.INPUT.COUNTRY",
                  })}
                </label>
                <StyledCountryDropDown
                  defaultOptionLabel="Select a country, man."
                  value={country}
                  onChange={(value) => {
                    setCountry(value);
                  }}
                  whitelist={['FR']}
                  showDefaultOption={false}
                />
              </div>
            </div>
            <div className="d-flex">
              <StyledStateDropDownContainer>
                <label
                  style={{
                    color: state !== "" ? "#5573A0" : "#5573A0",
                    fontFamily: "human_sans_400",
                    fontSize: "11px",
                    lineHeight: "12px",
                  }}
                >
                  {intl.formatMessage({
                    id: "MODAL.INPUT.STATE",
                  })} ({
                    intl.formatMessage({
                      id: "MODAL.OPTIONAL_FIELD",
                    })
                  })
                </label>
                <StyledStateDropDown
                  blankOptionLabel="No country selected, man."
                  defaultOptionLabel="Select State"
                  country={country}
                  value={state}
                  onChange={(value) => {
                    setState(value);
                  }}
                  labelType="short"
                />
              </StyledStateDropDownContainer>
              <MyInput
                label={intl.formatMessage({
                  id: "MODAL.INPUT.ZIP_CODE",
                })}
                value={zipCode}
                className="input-item"
                onChange={(e) => {
                  setZipCode(
                    e.target.value?.replace(/[^0-9]/g, "").slice(0, 5)
                  );
                }}
                width={
                  isMobile || isTablet
                    ? "100%"
                    : "100%"
                }
                placeholder={intl.formatMessage({
                  id: "MODAL.INPUT.ZIP_CODE.PLACEHOLDER",
                })}
              />
            </div>
            <div className="action-buttons" style={{ justifyContent: "center" }}>
              <MyButton
                onClick={() => {
                  becomeResponderHandler();
                }}
                className="submit-btn-form"
                disabled={disableButton()}
              >
                <b>
                {intl.formatMessage({
                    id: "BUTTON.BECOME_RESPONDER",
                  })}
                </b>
              </MyButton>
            </div>
          </>
      </BecomeResponderFormContainer>
  );
};

export default BecomeResponderForm;
