import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { MetronicI18nProvider } from "./i18n";
import 'bootstrap/dist/css/bootstrap.min.css';

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <MetronicI18nProvider>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </MetronicI18nProvider>
);

