import React, { useContext, useEffect } from "react";
import {
  PresSubscribeFormContainer,
  StyledPhoneInput,
  GenderListContainer,
} from "./style";
import MyInput from "../Elements/MyInput";
import { MyAppContext } from "../../context/MyAppContext";
import { useIntl } from "react-intl";
import "react-phone-number-input/style.css";
import { FaTimes } from "react-icons/fa";
import MyDatePicker from "../Elements/MyDatePicker";
import { MisterImage, MadamImage } from "../../assests";
import MyButton from "../Elements/MyButton/index";
import { BsPatchCheckFill } from "react-icons/bs";
import moment from "moment";
import axios from "axios";
import { useMediaQuery } from "react-responsive";

const PreSubscribeForm = () => {
  const {
    firstName,
    setFirstName,
    lastName,
    setLastName,
    email,
    setEmail,
    phone,
    setPhone,
    zipCode,
    setZipCode,
    dateOfBirth,
    setDateOfBirth,
    gender,
    setGender,
    setNotification,
    setIsPreSubscribeModalOpen,
    setCountryCode,
  } = useContext(MyAppContext);
  const intl = useIntl();
  const isMobile = useMediaQuery({ query: `(max-width: 768px)` });
  const isTablet = useMediaQuery({ query: `(max-width: 1000px)` });

  useEffect(() => {
    setFirstName("");
    setLastName("");
    setEmail("");
    setPhone("");
    setZipCode("");
    setDateOfBirth("");
    setGender("");
    setCountryCode("");
  }, []);

  const genderList = [
    {
      name: intl.formatMessage({
        id: "MODAL.INPUT.GENDER.MALE",
      }),
      id: 1,
    },
    {
      name: intl.formatMessage({
        id: "MODAL.INPUT.GENDER.FEMALE",
      }),
      id: 2,
    },
  ];

  const disableButton = () => {
    let emailCheck =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    let isValid = emailCheck.test(email);
    if (
      firstName === "" ||
      lastName === "" ||
      phone === "" ||
      zipCode === "" ||
      gender === ""
    ) {
      return true;
    } else {
      if (isValid) {
        return false;
      } else {
        return true;
      }
    }
  };

  const preSubscribeHandler = async () => {
    let postData = {
      firstName: firstName,
      lastName: lastName,
      email: email,
      phoneNumber: phone,
      zipCode: zipCode,
      dateOfBirth: moment(dateOfBirth).format("yyyy-MM-DD"),
      gender: gender.name,
    };

    axios
      .post(
        `${process.env.REACT_APP_API_URL}/presubscribe`,
        postData
      )
      .then((res) => {
        setNotification({
          message: intl.formatMessage({
            id: "MODAL.BECOME_RESPONDER.NOTIFICATION",
          }),
          table: ``,
          backGroundColor: `#34CD77`,
          icon: <BsPatchCheckFill color="white" size={20} />,
        });
        setIsPreSubscribeModalOpen(false);
        setFirstName("");
        setLastName("");
        setEmail("");
        setPhone("");
        setZipCode("");
        setDateOfBirth("");
        setGender("");
        setCountryCode("");
      })
      .catch((err) => {
        const errors = err?.response?.data.errors;
        if (errors && errors?.length > 0) {
          return setNotification({
            message: errors[0].msg,
            backGroundColor: `#ED2626`,
            icon: <FaTimes color="white" size={20} />,
          });
        } else {
          return setNotification({
            message: intl.formatMessage({
              id: "MODAL.BECOME_RESPONDER.NOTIFICATION_ERROR",
            }),
            backGroundColor: `#ED2626`,
            icon: <FaTimes color="white" size={20} />,
          });
        }
      });
  };

  return (
    <PresSubscribeFormContainer>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          marginBottom: "19px",
        }}
      >
        <h2 className="header">{intl.formatMessage({
          id: "MODAL.PRE_SUBSCRIBE",
        })}</h2>
        <h4 className="sub-header">{intl.formatMessage({
          id: "MODAL.PRE_SUBSCRIBE.SUB_TITLE",
        })}</h4>
      </div>
      <div className="d-flex">
        <MyInput
          label={intl.formatMessage({
            id: "MODAL.INPUT.FIRST_NAME",
          })}
          value={firstName}
          className="input-item"
          onChange={(e) => {
            let value = e.target.value;
            setFirstName(value);
          }}
          placeholder={intl.formatMessage({
            id: "MODAL.INPUT.FIRST_NAME.PLACEHOLDER",
          })}
        //   required={true}
        />
        <MyInput
          label={intl.formatMessage({
            id: "MODAL.INPUT.LAST_NAME",
          })}
          value={lastName}
          onChange={(e) => {
            let value = e.target.value;
            setLastName(value);
          }}
          className="input-item"
          placeholder={intl.formatMessage({
            id: "MODAL.INPUT.LAST_NAME.PLACEHOLDER",
          })}
        />
      </div>
      <div className="d-flex">
        <MyInput
          label={intl.formatMessage({
            id: "MODAL.INPUT.EMAIL",
          })}
          type="email"
          value={email}
          className="input-item"
          onChange={(e) => {
            setEmail(e.target.value);
          }}
          placeholder={intl.formatMessage({
            id: "MODAL.INPUT.EMAIL.PLACEHOLDER",
          })}
        />
        <div className="phone-number-div">
          <label
            style={{
              color: phone !== "" ? "#5573A0" : "#5573A0",
            }}
          >
            {intl.formatMessage({
              id: "MODAL.INPUT.PHONE_NUMBER",
            })}
          </label>
          <StyledPhoneInput
            placeholder={intl.formatMessage({
              id: "MODAL.INPUT.PHONE_NUMBER.PLACEHOLDER",
            })}
            international
            value={phone}
            defaultCountry="FR"
            limitMaxLength={true}
            onChange={setPhone}
            countryCallingCodeEditable={false}
          />
        </div>
      </div>
      <div
        className="d-flex"
        style={{
          alignItems: isMobile || isTablet ? "start" : "",
        }}
      >
        <div
          className="d-flex"
          style={{
            gap: "6px",
            alignItems: isMobile || isTablet ? "start" : "",
            width: isMobile || isTablet ? "-webkit-fill-available" : "",
          }}
        >
          <MyDatePicker
            label={intl.formatMessage({
              id: "MODAL.INPUT.DATE_OF_BIRTH",
            })}
            value={dateOfBirth}
            className="input-item"
            onChange={(date) => {
              setDateOfBirth(date);
            }}
            width={isMobile || isTablet ? "-webkit-fill-available" : "169px"}
            placeholder={intl.formatMessage({
              id: "MODAL.INPUT.DATE_OF_BIRTH.PLACEHOLDER",
            })}
          />
          <MyInput
            label={intl.formatMessage({
              id: "MODAL.INPUT.ZIP_CODE",
            })}
            value={zipCode}
            className="input-item"
            onChange={(e) => {
              setZipCode(
                e.target.value?.replace(/[^0-9]/g, "").slice(0, 5)
              );
            }}
            width={isMobile || isTablet ? "-webkit-fill-available" : "169px"}
            placeholder={intl.formatMessage({
              id: "MODAL.INPUT.ZIP_CODE.PLACEHOLDER",
            })}
          />
        </div>
        <div className="d-flex-column">
          <label
            style={{
              color: gender !== "" ? "#5573A0" : "#5573A0",
            }}
          >
            {intl.formatMessage({
              id: "MODAL.INPUT.GENDER",
            })}
          </label>
          <GenderListContainer>
            {genderList.map((genderEl, index) => {
              return (
                <div
                  className="gender-div"
                  style={{
                    // opacity: genderEl?.id === gender?.id ? "1" : "0.2",
                    backgroundColor:
                      genderEl?.id === gender?.id
                        ? "rgb(200 220 250 / 51%)"
                        : "rgb(200 220 250 / 18%)",
                    color:
                      genderEl?.id === gender?.id ? "#056DFF" : "#5573A0",
                  }}
                  onClick={() => {
                    setGender(genderEl);
                  }}
                  key={index}
                >
                  <img
                    src={genderEl?.id === 1 ? MisterImage : MadamImage}
                    style={{
                      filter:
                        genderEl?.id === 2
                          ? "invert(54%) sepia(41%) saturate(4923%) hue-rotate(179deg) brightness(88%) contrast(101%)"
                          : "",
                      opacity: genderEl?.id === gender?.id ? "1" : "0.2",
                    }}
                    alt=""
                  />
                  {genderEl?.id === gender?.id ? (
                    <b>{genderEl.name}</b>
                  ) : (
                    <span>{genderEl.name}</span>
                  )}
                </div>
              );
            })}
          </GenderListContainer>
        </div>
      </div>
      <div className="action-buttons" style={{ justifyContent: "center" }}>
        <MyButton
          onClick={() => {
            preSubscribeHandler();
          }}
          className="submit-btn"
          disabled={disableButton()}
        >
          <b>
            {intl.formatMessage({
              id: "BUTTON.SUBMIT",
            })}
          </b>
        </MyButton>
      </div>
    </PresSubscribeFormContainer>
  );
};

export default PreSubscribeForm;
